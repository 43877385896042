<template>
  <div class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center">Create Job</h1>
        <div class="flex items-center gap-3">
          </div>
      </div>
    </div>
    <div class="flex justify-center" >
        <div class=" card rounded-xl bg-white p-4 my-4 sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12">
          <div class=" grid grid-cols-2 gap-4">
            <div class="mb-4 sm:mb-0">
              <Dropdown 
              :inputext="searchForCustomer" 
              :inputId="'searchwp-Customer'"
              :lableText="'Customer'"
              :placholderText="`Customer`"
              :autoFocus="false"
              :showArrow="true"
              :setReadOnly="false"
              :searchCallApi="true"
              :fieldError="errObj.custErr !== ''"
              :showPlus="true"
              :keyName="'companyName'"
              :listId="'customerlistid'"
              :items="customersList"
              @enterkeyPressAction="errObj.custErr =''"
              @inputChangeAction="callsearchCust"
              @addNew="addNewCustomer"
              @selectNewForVal="selectNewCustomer"
              />
              <p v-if="errObj.custErr !== ''" class="text-error heading-7">{{errObj.custErr}}</p>
          </div>
          <div class="mb-4 sm:mb-0">
            <TextField
              :inputId="'jobtitlename'"
              :textMaxlength="100"
              :showcharLimit="true"
              :inputext="addJobObj.jobTitle"
              :fieldError="errObj.titleErr !== ''"
              :placholderText="`Job Title`"
              :lableText="'Job Title'"
              :autoFocus="false"
              @keyPressAction="errObj.titleErr = ''"
              @inputChangeAction="(data) => addJobObj.jobTitle = data"  />

              <p class="text-error heading-7">{{errObj.titleErr}}</p>
          </div>
            <div v-if="showAddSelectAddr" class="mb-4 sm:mb-0">
              <Dropdown 
                :inputext="jobDisplayAdd" 
                :inputId="'jobloactioninput'"
                :lableText="'Select job location'"
                :placholderText="`Select job location`"
                :autoFocus="false"
                :showPlus="true"
                :showArrow="true"
                :setReadOnly="true"
                :searchCallApi="false"
                :keyName="'fullAddress'"
                :listId="'addresslistforjob'"
                :items="addressList"
                 @addNew="addNewAddress"
                @selectNewForVal="selectJobAddress"
                />
              <p v-if="errObj.custErr !== ''" class="text-error heading-7">{{errObj.custErr}}</p>
          </div>
          <div class=" col-span-2 mb-4">
            <TextAreaInput
              :inputId="'discriptioncon'"
              :inputext="addJobObj.jobDescription"
              :placholderText="`Write description here...`"
              :lableText="'Job Description'"
              :inputType="'text'"
              :autoFocus="false"
              :textMaxlength="1000"
              :showcharLimit="true"
              :cols="50"
              :rows="3"
              :setReadOnly="false"
              @inputChangeAction="(data) => addJobObj.jobDescription = data"  />
          </div>
          </div>
          <div>
            <SheduleVisit :isLead="islead" :isFromSchedule="true" :isSelect="true" :sTime="sendStartTime" :eTime="sendEndTime" :title="'Schedule'" :showEmployeeSelection="true" :showJobItemSelection="true" :visitErr="visitErr" />
          </div>
          <div class="flex justify-end ">
            <div class="text-rigth flex gap-2">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="$router.go(-1)"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="crateJob()"/>
            </div>
          </div>
        </div>
    </div>
    <div>
    </div>
    <AddCustomer v-if="addCustomer" />
    <AddressAdd :showAdditional="true" v-if="addNewjobAdd" />
   </div>
</template>
<script>
import MyJobApp from "@/api/MyJobApp.js";
import Button from '@/View/components/globalButton.vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import SheduleVisit from '@/View/job/components/SheduleVisit.vue'
import Dropdown from '@/View/components/dropdown.vue'
import TextField from '@/View/components/textfield.vue'
import AddCustomer from '@/View/customer/components/AddCustomer.vue'
import deboucneMixin from '@/mixins/debounce.js'
import AddressAdd from '@/View/components/AddressAdd.vue'
export default {
  name: "customer",
  components: {
    Button,
    TextAreaInput,
    AddCustomer,
    AddressAdd,
    TextField,
    SheduleVisit,
    Dropdown,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      islead: false,
      sendStartTime: '',
      sendEndTime: '',
      jobDisplayAdd: '',
      customersList: [],
      addCustomer: false,
      visitErr: false,
      showAddSelectAddr: false,
      addNewjobAdd: false,
      addressList: [],
      searchForCustomer: '',
      searchApitext: '',
      errObj: {
        custErr:'',
        titleErr:''
      },
      getCustList: () => {},
      addJobObj: {
        jobId: 0,
        customerId: 0,
        jobNumber: '',
        jobTitle: '',
        jobDescription: '',
        jobStartTime: '',
        jobEndTime: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        latitude: 0,
        longitude: 0,
        geofenceRangeDiameter: 0,
        fullAddress: '',
        houseNo: '',
        floorNumber: '',
        buildingName: '',
        directionNote: '',
        contactNumber: '',
        contactName: '',
        assignToList: [],
        isBillable: false,
        repeatedType: '',
        repeatedDayOrDate: 0,
        noOfVisit: 0,
        visitList: [],
        itemList: [],
        toolList: [],
        jobTypeId: 0,
        isVipJob: false,
        jobEstimation: 0,
        visitCheckList: ""
      }
    };
  },
  created() {
    window.addEventListener('resize', this.resizeWindowHandler())
  },
  computed: {
  },
  watch: {
  },
  beforeMount() {
    console.log("route", this.$route.query);
    this.sendStartTime = JSON.parse(this.$route.query.startTime)
    this.sendEndTime = JSON.parse(this.$route.query.endTime)
  },
  mounted() {
    if (this.$route.query.customerId > 0) {
      this.getCustLeadInfo(parseInt(this.$route.query.customerId))
    }
     this.getCustList = this.debounce(function () {
      this.getCustomerList()
    }, 500)
      this.getCustomerList()
    this.$root.$on("addCustomerHandler", (response, data) => {
      if (response && this.addCustomer) {
        this.selectNewCustomer(data)
      }
      this.addCustomer = false
    });
    this.$root.$on('addAddressHandler', (response, data) => {
      if (response && data !== null && this.addNewjobAdd) {
        this.setAddress(data)
      }
        this.addNewjobAdd = false
    })
    this.$root.$on('setItemSelectedHandler', (response) => {
      this.addJobObj.itemList = response
    })
    this.$root.$on('finalvisitlistHandler', (response) => {
      this.addJobObj.visitList = response
      this.visitErr = false
    })
    this.$root.$on('setSelectedEmpHandler', (response) => {
      this.addJobObj.assignToList = response
    })
    this.$root.$on('setSheduleDataEmpHandler', (response) => {
      if (response.repeatedType) {
        this.addJobObj.repeatedType = response.repeatedType
      }
      if (response.repeatedDayOrDate) {
        this.addJobObj.repeatedDayOrDate = response.repeatedDayOrDate
      }
      if (response.repeatedDayOrDate) {
        this.addJobObj.repeatedDayOrDate = response.repeatedDayOrDate
      }
    })
  },
  beforeDestroy() {
    this.$root.$off("addCustomerHandler");
    this.$root.$off("setItemSelectedHandler");
    this.$root.$off("addAddressHandler");
    this.$root.$off("finalvisitlistHandler");
    this.$root.$off("setSelectedEmpHandler");
    this.$root.$off("setSheduleDataEmpHandler");
  },
  methods: {
    crateJob () {
      this.noOfVisit = this.addJobObj.visitList.length + 1
      let isValid = true
      if (this.addJobObj.jobTitle.trim() === '') {
        this.errObj.titleErr = 'Job title is required'
        isValid = false
      }
      if (this.addJobObj.customerId === 0) {
        this.errObj.custErr = 'Please select customer'
        isValid = false
      }
      if (this.addJobObj.visitList.length < 1) {
        this.visitErr = true
        isValid = false
      } else {
        this.addJobObj.jobStartTime = this.addJobObj.visitList[0].visitStartDateTime
        this.addJobObj.jobEndTime = this.addJobObj.visitList[0].visitEndDateTime
      }
      if (isValid) {
        this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
        MyJobApp.createNewJob(
          this.addJobObj,
          (response) => {
              this.$router.go(-1)
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            (error) => {
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
        );
      }
      
    },
    setAddress (data) {
      this.jobDisplayAdd = data !== null ? data.fullAddress : ''
      this.addJobObj.addressLine1 = data !== null ? data.addressLine1 : ''
      this.addJobObj.addressLine2 = data !== null ? data.addressLine2 : ''
      this.addJobObj.city = data !== null ? data.city : ''
      this.addJobObj.state = data !== null ? data.state : ''
      this.addJobObj.zip = data !== null ? data.zip : ''
      this.addJobObj.latitude = data !== null ? data.latitude : 0
      this.addJobObj.longitude = data !== null ? data.longitude : 0
      this.addJobObj.country = data !== null ? data.country : ''
      this.addJobObj.geofenceRangeDiameter =  data !== null ? data.selectedRadius : ''
      this.addJobObj.fullAddress =  data !== null ? data.fullAddress : ''
      this.addJobObj.houseNo =  data !== null ? data.houseNo : ''
      this.addJobObj.floorNumber =  data !== null ? data.floorNumber : ''
      this.addJobObj.buildingName =  data !== null ? data.buildingName : ''
      this.addJobObj.directionNote =  data !== null ? data.directionNote : ''
      this.addJobObj.contactNumber =  data !== null ? data.contactNumber : ''
      this.addJobObj.contactName =  data !== null ? data.contactName : ''
    },
    selectJobAddress (data) {
      this.jobDisplayAdd = data !== null ? data.fullAddress : ''
      this.addJobObj.addressLine1 = data !== null ? data.addressLine1 : ''
      this.addJobObj.addressLine2 = data !== null ? data.addressLine2 : ''
      this.addJobObj.city = data !== null ? data.city : ''
      this.addJobObj.state = data !== null ? data.state : ''
      this.addJobObj.zip = data !== null ? data.zip : ''
      this.addJobObj.latitude = data !== null ? data.latitude : 0
      this.addJobObj.longitude = data !== null ? data.longitude : 0
      this.addJobObj.country = data !== null ? data.country : ''
      this.addJobObj.directionNote = data !== null ? data.directionNote : ''
      this.addJobObj.houseNo = data !== null ? data.houseNo : ''
      this.addJobObj.floorNumber = data !== null ? data.floorNumber : ''
      this.addJobObj.buildingName = data !== null ? data.buildingName : ''
    },
    selectNewCustomer (data) {
      if (data !== null) {
          this.islead = data.isLead  
          this.jobDisplayAdd = ''
          this.addJobObj.addressLine1 = ''
          this.addJobObj.addressLine2 = ''
          this.addJobObj.city = ''
          this.addJobObj.state = ''
          this.addJobObj.zip = ''
          this.addJobObj.latitude = ''
          this.addJobObj.longitude = ''
          this.addJobObj.country = ''
          this.addJobObj.directionNote = ''
          this.addJobObj.houseNo = ''
          this.addJobObj.floorNumber = ''
          this.addJobObj.buildingName = ''
          this.errObj.custErr = ''
            this.searchForCustomer = data.companyName
            this.addJobObj.customerId = data.customerId
            this.showAddSelectAddr = true
            this.getCustDetails(data.customerId)
        } else {
            this.searchForCustomer = ''
            this.addJobObj.customerId = 0
        }
    },
    addNewCustomer () {
        this.addCustomer = true
    },
    addNewAddress () {
        this.addNewjobAdd = true
    },
    callsearchCust (text) {
      this.searchApitext = text
     this.getCustList()
    },
    getCustomerList () {
      MyJobApp.getGlobalCustLeadList(
        0,
        500,
        this.searchApitext,
        '',
        response => {
           let tempList = response.Data.tableRow !== null ? response.Data.tableRow : []
           tempList.forEach(e => {
            let lead = e.isLead ? '(lead)' : '(customer)'
            e.companyName = e.companyName + ' ' + lead
            this.customersList.push(e)
           });
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 158
    },
    getCustLeadInfo(custId) {
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
      MyJobApp.getCustomerDetail(
        custId,
        (response) => {
          this.searchForCustomer = response.Data.companyName
          this.addJobObj.customerId = response.Data.customerId
          this.getCustDetails(response.Data.customerId)
          this.showAddSelectAddr = true
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getCustDetails(custId) {
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
      MyJobApp.getCustomerDetail(
        custId,
        (response) => {
          this.addressList = response.Data.addressList !== null ? response.Data.addressList : []
          if (this.addressList.length === 1) {
            this.jobDisplayAdd = this.addressList[0].fullAddress
            this.addJobObj.addressLine1 = this.addressList[0].addressLine1
            this.addJobObj.addressLine2 = this.addressList[0].addressLine2
            this.addJobObj.city = this.addressList[0].city
            this.addJobObj.state = this.addressList[0].state
            this.addJobObj.zip = this.addressList[0].zip
            this.addJobObj.latitude = this.addressList[0].latitude
            this.addJobObj.longitude = this.addressList[0].longitude
            this.addJobObj.country = this.addressList[0].country
            this.addJobObj.directionNote = this.addressList[0].directionNote
            this.addJobObj.houseNo = this.addressList[0].houseNo
            this.addJobObj.floorNumber = this.addressList[0].floorNumber
            this.addJobObj.buildingName = this.addressList[0].buildingName
          }
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
  },
};
</script>
<style scoped>

</style>